<template>
  <section class="newproduct-form">
    <div class="newproduct-form__grid">
      <label :class="[ errors.name ? 'form--error' : null ]">
        Product Name*
        <textarea type="text" v-model="form.name" placeholder="name" rows="2" />
      </label>
      <form-error :message="errors.name" />
      <label :class="[ errors.loCost ? 'form--error' : null ]">
        Product Cost*
        <input type="number" min="0" v-model="form.loCost" />
      </label>
      <form-error :message="errors.loCost" />
      <label :class="[ errors.itemCost ? 'form--error' : null ]">
        Item Cost
        <input type="number" min="0" v-model="form.itemCost" />
      </label>
      <form-error :message="errors.itemCost" />
      <label :class="[ errors.invoiceCost ? 'form--error' : null ]">
        Invoice Cost
        <input type="number" min="0" v-model="form.invoiceCost" />
      </label>
      <form-error :message="errors.invoiceCost" />
      <label :class="[ errors.description ? 'form--error' : null ]">
        Description*
        <textarea
          v-model="form.description"
          rows="5"
          placeholder="product description"
        />
      </label>
      <form-error :message="errors.description" />
      <!-- <label>
        Is Featured
        <select v-model="form.isFeatured">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </label>-->
      <label for="#">
        Categories
        <simplebar class="categories--overflow" data-simplebar-auto-hide="false">
          <ul class="categories--list">
            <li v-for="category in getCategories" :key="category._id">
              <label>
                <input type="checkbox" v-model="form.categories" :value="category._id" />
                {{ category.name }}
              </label>
            </li>
          </ul>
        </simplebar>
      </label>
      <label>
        Tags
        <input type="text" v-model="tags" placeholder="comma separated" />
      </label>
      <label>
        Is Inventoried
        <select v-model="form.isInventoried">
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </label>
      <div class="flex__center">
        <button class="btn btn__green text__md" @click="submit">Create Product</button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
export default {
  data() {
    return {
      isValidated: true,
      permission: ["super-admin"],
      tags: "",
      form: {
        name: "",
        loCost: 0,
        itemCost: 0,
        invoiceCost: 0,
        description: "",
        categories: [],
        tags: [],
        isInventoried: false
      },
      errors: {
        name: null,
        loCost: null,
        itemCost: null,
        invoiceCost: null,
        description: null
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails", "getCategories"]),
    canSubmit() {
      return this.permission.includes(this.getUserDetails.permission);
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startCreateProduct"]),
    async submit() {
      if (!this.canSubmit)
        return createError(
          "Access denied",
          "You do not have permission to perform this action"
        );
      this.validate();
      if (this.isValidated) {
        this.form.tags = this.tags
          ? this.tags
              .toLowerCase()
              .split(",")
              .map(item => item.trim())
          : [];
        this.startSetIsSubmitting({ bool: true, heading: "Creating" });
        try {
          await this.startCreateProduct(this.form);
          this.startSetIsSubmitting({ bool: false });
        } catch (err) {
          this.startSetIsSubmitting({ bool: false });
        }
      }
    },
    validate() {
      this.isValidated = true;
      this.errors.name = this.form.name === "" ? "Please enter a name" : null;
      this.errors.loCost =
        this.form.loCost === null ||
        isNaN(parseFloat(this.form.loCost)) ||
        parseFloat(this.form.loCost) < 0
          ? "Cost must be entered and 0 or more"
          : null;
      this.errors.itemCost =
        isNaN(parseFloat(this.form.itemCost)) ||
        parseFloat(this.form.itemCost) < 0
          ? "Cost must be 0 or more"
          : null;
      this.errors.invoiceCost =
        isNaN(parseFloat(this.form.invoiceCost)) ||
        parseFloat(this.form.invoiceCost) < 0
          ? "Cost must be 0 or more"
          : null;
      this.errors.description =
        this.form.description === ""
          ? "Please enter a description for this product"
          : null;
      for (let key in this.errors) {
        if (this.errors[key] === null) {
          this.isValidated = this.isValidated && true;
        } else {
          this.isValidated = this.isValidated && false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.newproduct-form__grid {
  @include grid($cols: 1fr, $col-gap: 15px, $row-gap: 15px);
  max-width: 700px;
  label {
    font-weight: bold;
    @include grid($cols: 1.25fr 2fr, $col-gap: 10px, $align: center);
  }
}
.categories--overflow {
  max-height: 150px;
  border: 1px solid grey;
  border-radius: 3px;
}
.categories--list {
  background-color: #fff;
  @include grid($cols: 1fr, $col-gap: 10px, $row-gap: 5px);
  padding: 10px;
  li label {
    @include grid($cols: 1fr 5fr, $col-gap: 15px, $align: center);
  }
}
@media (min-width: $md) {
  .newproduct-form__grid label {
    grid-template-columns: 1fr 2fr;
  }
  .categories--list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: $xl) {
  .newproduct-form__grid label {
    grid-template-columns: 1fr 3fr;
  }
}
</style>