<template>
  <section class="newproduct-header">
    <div class="container">
      <router-link to="/admin/products" class="breadcrumb">
        <font-awesome-icon icon="arrow-left" size="sm" class="mr-10" />Back to Products
      </router-link>
      <h1 class="text__center padding__sm">New Product</h1>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>