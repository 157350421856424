<template>
  <section class="newproduct">
    <Header />
    <div class="container">
      <div class="form__grid margin__sm">
        <p>
          Filling out this form will begin the process of creating a new product.
          It will be created as a test product to begin with in order to avoid incomplete products from being ordered.
          Once complete you will be redirected to the product page to add further information like images and customization options.
        </p>
        <Form />
      </div>
    </div>
  </section>
</template>

<script>
import Header from "./Header";
import Form from "./Form";
export default {
  components: {
    Header,
    Form
  },
  metaInfo: {
    title: "New Product | WOW"
  }
};
</script>

<style scoped lang="scss">
</style>